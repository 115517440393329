const Intro = {
    jumbotronSubTexts: [
        "这是一个从事较为硬核的《东方Project》同人创作活动的同人社团。",
        "这是一个由一位喜好《东方Project》的工程师所建立的摸鱼同人社团。"
    ],
    assoBasicInfo: [
        { key: "assoName", label: "社团名称", value: "Z神社" },
        { key: "assoNameLatin", label: "拉丁化名称", value: "ZJinJa" },
        { key: "productType", label: "主要作品类型", value: "同人游戏、电子/硬件/软件周边、PCB装饰品、绘画、漫画、小说、音乐" },
        { key: "supervisor", label: "主催", value: "ZRY" },
        { key: "area", label: "所在地", value: "中华人民共和国" },
    ],
    creativeConcept: [
        "因为这是由一位工程师建立的硬核同人社团，所以整个创作格调方面来看都显得比较硬核。",
        "社团主催对《东方Project》同人创作的个人理解是，同人创作是将喜好原作品的人的个人灵感与对作品的喜好结合到一起，"
        + "产生一系列多元化的新作品。这没有固定的形式和载体约束，也没有创作思想上的具体束缚，多元化和个性化是核心。",
        "在这样的思想基础上建立的本同人社团，将给大家带来一系列硬核的同人作品——不仅有具备完整的、系统的世界观的，"
        + "例如OHF Project这样的企划，也有具有一定工程/技术含量同时兼具特殊情怀的例如Yakumo V8R0这样的企划，"
        + "除此之外也有一些零碎的、意识流的但具有一定特殊情结或是具有特殊neta的形散而神不散的小作品……",
        "类似这样的社团也许在《东方Project》同人圈子内比较少见，希望诸位会喜欢。谢谢。"
    ]
}

export default Intro
<template>
  <div>
    <b-jumbotron
      header="社团介绍"
      fluid
    >
      <ul>
        <li :class="{li_in_jumbotron: true}" v-for="(v,i) in jumbotronSubTexts" :key="i">{{v}}</li>
      </ul>
    </b-jumbotron>
    <b-container>
      <b-card-group deck>
        <b-card
          border-variant="tmprimary"
          header-bg-variant="tmprimary"
          header-text-variant="white"
        >
          <h5 slot="header">基本信息</h5>
          <b-table :items="this.basicInfoTable.items" :fields="this.basicInfoTable.fields" stacked />
        </b-card>
        <b-card
          border-variant="tmprimary"
          header-bg-variant="tmprimary"
          header-text-variant="white"
        >
          <h5 slot="header">创作理念</h5>
          <b-card-text>
            <p v-for="(v, i) in this.creativeConcept" :key="i">{{v}}</p>
          </b-card-text>
        </b-card>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
import dsIntro from "@/datasrc/intro.js"
export default {
  name: "intro",
  data() {
    return {
      jumbotronSubTexts: dsIntro.jumbotronSubTexts,
      creativeConcept: dsIntro.creativeConcept,
    };
  },
  computed: {
    basicInfoTable(){
      var itemdict = {}
      var itemlist = [itemdict]
      var fieldlist = []
      dsIntro.assoBasicInfo.forEach(iv => {
        fieldlist.push({key: iv.key, label: iv.label})
        itemdict[iv.key] = iv.value
      });
      return {
        items: itemlist,
        fields: fieldlist 
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.li_in_jumbotron{
  font-size 20px
}
</style>